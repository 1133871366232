import { Controller } from '@hotwired/stimulus'

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
}

export default class extends Controller {
  static targets = ['scrollArea', 'anchorSection']

  connect() {
    this.startObservingSections();
  }

  disconnect() {
    this.stopObservingSections();
  }

  startObservingSections() {
    if (!supportsIntersectionObserver()) {
      console.warn(`This browser doesn't support IntersectionObserver`);
      return;
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateScrollNavigation.bind(this),
      {
        threshold: 0,
        rootMargin: '-50% 0px'
      }
    );

    this.anchorSectionTargets.forEach((anchorSectionEl) => {
      this.intersectionObserver.observe(anchorSectionEl)
    });
  }

  updateScrollNavigation(entries) {
    entries.forEach((entry) => {
      entry.target.dataset.isIntersecting = entry.isIntersecting;
      const hash = '#' + entry.target.id;
      const navEls = document.querySelectorAll(`.anchor-link[href="${hash}"]`)

      if (entry.isIntersecting) {
        navEls.forEach((navEl) => navEl.classList.add('anchor-link-active'))
      } else {
        navEls.forEach((navEl) => navEl.classList.remove('anchor-link-active'))
      }
    });
  }

  stopObservingSections() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
